<template>
  <FocusTrap>
      <div  id="mycard" class="card">

      <div class="card-header header-elements-inline" >
        <h5 class="card-title">Group Information</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <form action="#">

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Name:</label>
            <div class="col-md-10">
              <input  id="txtname" type="text" class="form-control" placeholder="Enter Group Name here..." maxlength="100" autocomplete="off" v-if="itemgroup" v-model="itemgroup.name" >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Status:</label>
            <div class="col-md-10">
              <select class="form-control" v-if="itemgroup" v-model="itemgroup.status">
                <option value="Active" selected>Active</option>
                <option value="Disabled">Disabled</option>
                <option value="Suspended">Suspended</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Nature:</label>
            <div class="col-md-4">
              <select class="form-control" v-if="itemgroup" v-model="itemgroup.nature">
                <option value="1">Physical Product</option>
                <option value="2">Virtual Product</option>
                <option value="3">Services</option>
              </select>
            </div>
            <label class="col-md-2 col-form-label">HSN:</label>
            <div class="col-md-4">
              <input type="text" class="form-control" placeholder="Enter HSN here..." maxlength="8" v-if="itemgroup" v-model="itemgroup.hsn">
            </div>
          </div>



          <div class="form-group row">
            <label class="col-md-2 col-form-label">GST %:</label>
            <div class="col-md-4">
              <input type="number" class="form-control" placeholder="Enter GST Rate..." max="42" v-if="itemgroup" v-model="itemgroup.gstrate">
            </div>
            <label class="col-md-2 col-form-label">ITC %:</label>
            <div class="col-md-4">
              <input type="number" class="form-control" placeholder="Enter ITC here..." max="100" v-if="itemgroup" v-model="itemgroup.itcrate" disabled>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">Unit</label>
            <div class="col-md-2">
              <select class="form-control" v-if="itemgroup" v-model="itemgroup.unit.id">
                <option value=1>Nos</option>
                <option value=2>Kgs</option>
                <option value=3>Mtrs</option>
                <option value=4>Ltrs</option>
              </select>
            </div>
            <label class="col-md-2 col-form-label">Conv Fact:</label>
            <div class="col-md-2">
              <input type="number" class="form-control" placeholder="Conv Fact" min="1" max="100" v-if="itemgroup" v-model="itemgroup.conv_fact" disabled >
            </div>

            <label class="col-md-2 col-form-label">Sub Unit:</label>
            <div class="col-md-2">
              <select class="form-control" v-if="itemgroup" v-model="itemgroup.subunit.id" disabled>
                <option value=1>Nos</option>
                <option value=2>Kgs</option>
                <option value=3>Mtrs</option>
                <option value=4>Ltrs</option>
              </select>
            </div>

          </div>

        </form>
      </div>

      <div class="card-footer">
        <div class="text-right" >
          <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'ItemGroupForm',
  store,
  data () {
    return {
      itemgroup: {}
    }
  },
  props: {
    mygroup: {
      type: Object,
      default: () =>  JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":1,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}')
    }
  },
  beforeMount () {
    this.itemgroup = this.mygroup; // save props data to itself's data and deal with tms
  },
  component: {

  },
  created () {
    this.$data.itemgroup = JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":1,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}')
  },
  mounted () {
    $('#txtname').focus();
    // $('.form-control-select2').select2()
  },
  methods: {
    closeWindow(){
      this.$emit('itemgroup_window_closed');
    },
    clear(){
      this.$data.itemgroup = JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":1,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}')
    },
    saveDocument(){
      const self = this;

      self.$data.itemgroup.nature = parseInt(self.$data.itemgroup.nature);
      self.$data.itemgroup.gstrate = parseFloat(self.$data.itemgroup.gstrate);
      self.$data.itemgroup.itcrate = parseFloat(self.$data.itemgroup.itcrate);
      self.$data.itemgroup.conv_fact = parseInt(self.$data.itemgroup.conv_fact);
      self.$data.itemgroup.unit.id = parseInt(self.$data.itemgroup.unit.id);
      self.$data.itemgroup.subunit.id = parseInt(self.$data.itemgroup.subunit.id);

      if(self.$data.itemgroup.name.trim().length < 1){
        alert("Invalid Name");
        return
      }else if(self.$data.itemgroup.unit.id < 1){
        alert("Invalid Unit");
        return
      }else if(self.$data.itemgroup.subunit.id < 1){
        alert("Invalid Sub Unit");
        return
      }else if(self.$data.itemgroup.conv_fact < 1){
        alert("Invalid Conversion Factor");
        return
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      const requestOptions = {
        method:  (self.$data.itemgroup.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.itemgroup)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/itemgroup`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success"} );
          self.group = JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":1,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}');
          self.$emit('itemgroup_saved');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(()=>{
        $('#mycard').unblock();
      });
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
